<template>
  <div>
    <md-card class="md-card-global-sales">
      <md-card-header class="md-card-header-icon md-card-header-blue">
        <div class="card-icon">
          <md-icon>assignment</md-icon>
        </div>

        <md-button
          class="md-primary float-right md-round md-sm"
          style="margin-top: -20px"
          :disabled="!tableHasContent"
          @click="onDownload"
        >
          <md-icon>get_app</md-icon>
          Download as XLS
        </md-button>
      </md-card-header>

      <md-card-content>
        <div class="md-layout">
          <div class="md-layout-item md-size-100">
            <pie-chart
              v-if="!loading"
              :chart-data="canceledProgrammes"
              class="responsive-height"
              :options="pieChartOptions"
            />
          </div>
        </div>
      </md-card-content>

      <md-card-content>
        <div class="md-layout">
          <div class="md-layout-item md-size-100">
            <vue-table
              v-if="vTable.headers"
              ref="vtable"
              :values="vTable.values"
              :headers="vTable.headers"
              :actions="vTable.actions"
              :options="{
                tableClass:'table'
              }"
              @onShowDetails="onShowDetails"
              @changed="getStudents"
            />
          </div>
        </div>
      </md-card-content>
    </md-card>
  </div>
</template>

<script>
import VueTable from '@/components/Tables/VueTable.vue';
import PieChart from '@/components/Charts/PieChart.vue';
import downloadFile from '@/utils/downloadFile';
import moment from 'moment';

export default {
  components: {
    VueTable,
    PieChart,
  },
  data() {
    return {
      vTable: {
        headers: [
          {
            title: 'programme_id',
            mask: 'Programme ID',
            sortable: true,
          },
          {
            title: 'student.student_number',
            mask: 'Student Number',
            sortable: true,
          },
          {
            title: 'student.full_name',
            mask: 'Student Name',
          },
          {
            title: 'start_date',
            mask: 'Start Date',
            sortable: true,
            dateFormat: true,
          },
          {
            title: 'finish_date',
            mask: 'Finish Date',
            sortable: true,
            dateFormat: true,
          },
          {
            title: 'programme_cancel_reason.title',
            sortable: true,
            mask: 'Cancel Reason',
          },
        ],
        actions: [
          {
            buttonClass: 'md-warning',
            tooltip: 'Show Details',
            callback: 'onShowDetails',
            icon: 'search',
          },
        ],
        values: {},
      },
      loading: false,
      canceledProgrammes: {
        datasets: [{
          data: [],
          backgroundColor: [],
          borderWidth: 1,
        }],
        labels: [],
      },
      search: '',
    };
  },
  computed: {
    pieChartOptions() {
      return {
        tooltips: {
          callbacks: {
            label(tooltipItem, data) {
              const i = tooltipItem.index;
              const label = data.labels[i];
              const value = `${parseFloat(data.datasets[0].data[tooltipItem.index]).toFixed(2)}%`;
              return label.slice(0, -1).concat(`${label.slice(-1)}: ${value}`);
            },
          },
        },
      };
    },
    tableHasContent() {
      return this.vTable.values.data?.length > 0;
    },
  },
  mounted() {
    this.$refs.vtable.init();
    this.getCancelledProgrammesPercentage();
  },
  methods: {
    getStudents(params) {
      const { search } = params;
      this.search = search;

      this.request(this.$API.PROGRAMMES_CANCEL_REASONS_REPORT, 'get', { ...params }, ({ data }) => {
        this.vTable.values = data;
      });
    },
    getCancelledProgrammesPercentage() {
      this.loading = true;
      this.request(this.$API.PROGRAMMES_CANCEL_REASONS_REPORT, 'get', { only_percentages: true }, ({ data }) => {
        data.forEach((programm) => {
          this.canceledProgrammes.labels.push(programm.title);
          this.canceledProgrammes.datasets[0].data.push(programm.percentage);
        });
      }, () => {
        this.loading = false;
      });
    },
    onShowDetails(item, isMiddleClick) {
      this.openLink({ name: 'ProgrammeDetails', params: { id: item.programme_id } }, isMiddleClick);
    },
    onDownload() {
      this.loading = true;

      this.request(
        'programmes/cancel_reasons_report',
        'download',
        {
          download: true,
          ...(!!this.search && { search: this.search }),
        },
        ({ data }) => {
          const filename = `cancelled_programmes_report${moment().format(
            'YYYY_MM_DD',
          )}.xlsx`;
          downloadFile(data, filename);
        },
        () => {
          this.loading = false;
        },
      );
    },
  },
};
</script>
